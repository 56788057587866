import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import { ManagerProfileGetResponse } from './profile.types';

//
//

export class ManagerAccount extends ZidderResource<ManagerAccount> {
  protected classInstance = ManagerAccount;
  public pathBase = 'v1/managers/account/';

  //

  async profile(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'profile', options);
    const result = await response.json<ManagerProfileGetResponse>();

    if (!result?.user?.store?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
