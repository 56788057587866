import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as AffiliatesTypes from './affiliates.types';
import * as CustomersListTypes from './customers-list.types';
import * as GetTypes from './get.types';
import * as ListTypes from './list.types';
import * as SeoTypes from './seo.types';

//
//

export class ManagerMarketing extends ZidderResource<ManagerMarketing> {
  protected classInstance = ManagerMarketing;
  public pathBase = 'v1/managers/';
  public campaignsSubPath = 'store/sms-campaigns';

  //

  async smsCampaignsList(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'store/sms-campaigns', {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<ListTypes.Response>();

    if (!result?.campaigns) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async smsCampaignsGet(params: GetTypes.Query, options?: ZidderRequestOptions) {
    const { id } = params;

    const response = await this.client.get(
      this.pathBase + this.campaignsSubPath + `/${id}`,
      options,
    );
    const result = await response.json<GetTypes.Response>();

    if (!result?.smsCampaign) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async smsCampaignsCustomersList(query: CustomersListTypes.Query, options?: ZidderRequestOptions) {
    const { id, ...rest } = query;

    const response = await this.client.get(
      this.pathBase + this.campaignsSubPath + `/${id}` + '/customers',
      {
        ...options,
        searchParams: { ...rest, ...options?.searchParams },
      },
    );
    const result = await response.json<CustomersListTypes.Response>();

    if (!result?.customers) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async saveSeo(data: SeoTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathBase + 'store/seo', {
      json: data,
      ...options,
    });

    const result = await response.json<SeoTypes.Response>();

    return result;
  }

  //

  async listAffiliates(
    query?: AffiliatesTypes.AffiliatesListQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase + 'store/affiliates', {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<AffiliatesTypes.AffiliatesListResponse>();

    if (!result?.affiliates) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getAffiliate({ id }: AffiliatesTypes.AffiliateQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + `store/affiliate/${id}`, {
      ...options,
    });
    const result = await response.json<AffiliatesTypes.AffiliateResponse>();

    if (!result?.affiliate) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async generateAffiliateCode(options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'store/affiliate/code', {
      ...options,
    });
    const result = await response.json<AffiliatesTypes.AffiliateCodeResponse>();

    return result;
  }

  //

  async createAffiliate(
    payload: AffiliatesTypes.CreateAffiliatePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'store/affiliate', {
      json: payload,
      ...options,
    });
    const result = await response.json<AffiliatesTypes.CreateAffiliateResponse>();

    return result;
  }

  //

  async editAffiliateCommission(
    payload: AffiliatesTypes.EditAffiliateCommissionPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(
      this.pathBase + `store/affiliate/${payload.affiliateId}/commission`,
      {
        ...options,
        json: payload.data,
      },
    );
    const result = await response.json<AffiliatesTypes.EditAffiliateCommissionResponse>();

    return result;
  }

  //

  async editAffiliatePersonalInfo(
    payload: AffiliatesTypes.EditAffiliatePersonalInfoPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(
      this.pathBase + `store/affiliate/${payload.affiliateId}/info`,
      {
        ...options,
        json: payload.data,
      },
    );
    const result = await response.json<AffiliatesTypes.EditAffiliatePersonalInfoResponse>();

    return result;
  }

  //

  // TODO:BACKEND @ahmed This Endpoint needs to be reviewed with the backend team due to
  // it returns false positive response (return message that user is deactivated but it's not)
  async toggleAffiliateStatus(
    payload: AffiliatesTypes.ToggleAffiliateStatusPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + `store/affiliate/${payload.id}/status`, {
      ...options,
      searchParams: { status: payload.status },
    });
    const result = await response.json<AffiliatesTypes.ToggleAffiliateStatusResponse>();

    return result;
  }

  //

  async deleteAffiliate(
    id: AffiliatesTypes.DeleteAffiliatePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.pathBase + `store/affiliate/${id}`, {
      ...options,
    });
    const result = await response.json<AffiliatesTypes.DeleteAffiliateResponse>();

    return result;
  }

  //

  async deleteBulkAffiliates(
    payload: AffiliatesTypes.DeleteBulkAffiliatesPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.pathBase + `store/affiliate/bulk`, {
      ...options,
      json: payload,
    });
    const result = await response.json<AffiliatesTypes.DeleteBulkAffiliateResponse>();

    return result;
  }
}
