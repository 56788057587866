import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { zidderGetHeaders } from '~/zidderjs/utils';

import * as ListTypes from './list.types';

//
//

export class ManagerZidpayDepositDetails extends ZidderResource<ManagerZidpayDepositDetails> {
  protected classInstance = ManagerZidpayDepositDetails;
  public pathBase = 'v1/managers/store/zidpay/deposits/details';

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      headers: zidderGetHeaders(),
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.items) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
