import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ListTypes from './list.types';

//
//

export class ManagerZidpayPaymentLinks extends ZidderResource<ManagerZidpayPaymentLinks> {
  protected classInstance = ManagerZidpayPaymentLinks;
  public pathBase = 'v1/managers/store/';

  //

  async paymentLinksList(query?: ListTypes.PaymentLinksListQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}payment-link/list/zidpay`, {
      ...options,
      searchParams: {
        ...query,
      },
    });

    const result = await response.json<ListTypes.PaymentLinksListResponse>();

    if (!result?.items?.length) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async paymentLinkDetails(
    data?: ListTypes.PaymentLinkDetailsQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.post(`${this.pathBase}payment-link/details/zidpay`, {
      ...options,
      json: data,
    });

    const result = await response.json<ListTypes.PaymentLinkDetailsResponse>();

    if (!result?.orderId) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  } //

  async paymentDetails(
    data?: ListTypes.PaymentLinkPaymentDetailsQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.post(`${this.pathBase}zidpay/payment`, {
      ...options,
      json: data,
    });

    const result = await response.json<ListTypes.PaymentDetailsResponse>();

    if (!result?.referenceId) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
