import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ListTypes from './list.types';

//
//

export class ManagerProducts extends ZidderResource<ManagerProducts> {
  protected classInstance = ManagerProducts;
  public pathBase = 'v1/products/';

  //

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<ListTypes.Response>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async get(id: string | number, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase + id, options);
    // TODO: @aziz add get product typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = await response.json<any>();

    if (!result?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
