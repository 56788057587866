import { SearchParamsOption } from 'ky';

import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as GetTypes from './get.types';
import * as GroupTypes from './groups.types';
import * as ListPrimaryDataTypes from './list-primary-data.types';
import * as ListTypes from './list.types';
import * as QuestionsTypes from './questions.types';

//
//

export class ManagerCustomers extends ZidderResource<ManagerCustomers> {
  protected classInstance = ManagerCustomers;
  public pathBase = 'v1/managers/store/customers/';
  public pathListPrimaryData = this.pathBase + 'primary-data-list/';

  public pathGroups = 'v1/managers/store/groups/';
  public pathGroupNameAvailability = this.pathGroups + 'name-availability';

  public pathQuestions = 'v1/questions/';

  //

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<ListTypes.Response>();

    if (!result?.customers) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async listPrimaryData(query?: ListPrimaryDataTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathListPrimaryData, {
      ...options,
      searchParams: {
        page: query?.page,
        perPage: query?.per_page,
        ...options?.searchParams,
      } as SearchParamsOption,
    });
    const result = await response.json<ListPrimaryDataTypes.Response>();

    if (!result?.customers) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async get(id: string | number, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + id, options);
    const result = await response.json<GetTypes.Response>();

    if (!result?.customer?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  // Groups
  async listGroups(query?: GroupTypes.ListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathGroups, {
      ...options,
      searchParams: {
        page: query?.page,
        search: query?.search,
        limit: query?.limit,
        ...options?.searchParams,
      } as SearchParamsOption,
    });

    const result = await response.json<GroupTypes.ResponseGroups>();

    if (!result?.customer_groups) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async createGroup(data: GroupTypes.CreateBody, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathGroups, {
      ...options,
      json: data,
    });

    const result = await response.json<GroupTypes.CreateResponse>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async editGroup(id: string, data: GroupTypes.EditBody, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathGroups + id, {
      ...options,
      json: data,
    });

    const result = await response.json<GroupTypes.EditResponse>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getGroup(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathGroups + id, options);
    const result = await response.json<GroupTypes.GetResponse>();

    if (!result?.data) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteGroup(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathGroups + id, options);
    const result = await response.json<GroupTypes.DeleteResponse>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async checkNameAvailability(name: string, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathGroupNameAvailability, {
      ...options,
      searchParams: { name },
    });

    const result = await response.json<GroupTypes.NameAvailabilityResponse>();

    if (!result?.data) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  // Questions
  async listQuestions(query?: QuestionsTypes.ListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathQuestions, {
      ...options,
      searchParams: {
        page: query?.page || 1,
        page_size: query?.page_size || 20,
        is_anonymous: query?.is_anonymous,
        is_published: query?.is_published,
        is_new: query?.is_new,
        date_from: query?.date_from,
        date_to: query?.date_to,
        question: query?.question,
        ordering: query?.ordering,
        by: query?.by,
      } as SearchParamsOption,
    });

    const result = await response.json<QuestionsTypes.ListResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
