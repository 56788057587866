import { HTTPError, KyRequest, KyResponse, Options } from 'ky';

import { Response as IResponse } from '../resources/manager/auth/login.types';

//
//

export const getToken = () => window?.localStorage?.getItem('_t');

export const saveToken = (token: string) => window?.localStorage?.setItem('_t', token);

export const removeToken = () => window?.localStorage?.removeItem('_t');

//

export const authTokenInterceptor = async (req: KyRequest) => {
  if (!req?.url) return;

  try {
    const url = new URL(req.url);

    if (!url?.pathname || guestEndpoints.includes(url.pathname)) return;

    const token = getToken();

    if (token) req.headers.set('x-manager-token', token);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('authTokenInterceptor-err', error);
  }
};

//

export const authTokenResponseHandler = async (
  _req: KyRequest,
  _options: Options,
  res: KyResponse,
) => {
  try {
    const url = new URL(res?.url);

    if (
      ![
        '/v1/managers/login',
        '/v1/managers/otp-auth/register',
        '/v1/managers/otp-auth/login',
      ].includes(url?.pathname)
    )
      return res;

    const result = (await res.clone().json()) as IResponse;

    if (result?.user?.access_token) saveToken(result.user.access_token);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('authTokenResponseHandler-err', error);
  }

  return res;
};

//

export const errorHandler = async (error: HTTPError) => {
  if (error?.response?.status === 401) {
    removeToken();
  }

  return error;
};

//

const guestEndpoints = ['/v1/managers/login'];
