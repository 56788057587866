import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as GetTypes from './get.types';
import * as ListTypes from './list.types';

//
//

export class ManagerAbandonedCarts extends ZidderResource<ManagerAbandonedCarts> {
  protected classInstance = ManagerAbandonedCarts;
  public pathBase = 'v1/managers/store/abandoned-carts/';

  //

  async list(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, options);
    const result = await response.json<ListTypes.Response>();

    if (!result?.['abandoned-carts']) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async get(id: string | number, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + id, options);
    const result = await response.json<GetTypes.Response>();

    if (!result?.abandoned_cart?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
