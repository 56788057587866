import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { zidderGetHeaders } from '~/zidderjs/utils';

import * as TransactionsTypes from './transactions.types';

//
//

export class ManagerZidpayTransactions extends ZidderResource<ManagerZidpayTransactions> {
  protected classInstance = ManagerZidpayTransactions;
  public pathBase = 'v1/managers/store/zidpay';

  async transactionsList(
    query?: TransactionsTypes.TransactionsListQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}/transactions`, {
      ...options,
      headers: zidderGetHeaders(),
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<TransactionsTypes.TransactionsListResponse>();

    if (!result?.items) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async transactionDetails(
    data?: TransactionsTypes.TransactionDetailsPayload,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.post(`${this.pathBase}/payment`, { json: data, ...options });
    const result = await response.json<TransactionsTypes.TransactionDetailsResponse>();

    if (!result?.referenceId) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
