import { KyInstance, KyResponse } from 'ky';

import * as ZidderErrors from '../errors';
import { ZidderHeaders } from '../types';
import { zidderGetHeaders } from '../utils';

//
//

export class ZidderResource<T> {
  protected readonly classInstance!: new (client: KyInstance) => T;

  constructor(protected readonly client: KyInstance) {}

  public setHeaders(headers: ZidderHeaders) {
    const instance = this.client.extend({
      headers: zidderGetHeaders(headers),
    });

    return new this.classInstance(instance) as T;
  }

  public throwUnexpectedResponse(response: KyResponse) {
    throw ZidderErrors.ZidderErrorUnexpectedResponse.createFromResponse(response);
  }
}
