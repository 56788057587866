import { Zidder } from '../classes';

import { authTokenInterceptor, authTokenResponseHandler, errorHandler } from './interceptors';

//
//

export const ZidderClient = new Zidder({
  prefixUrl: import.meta.env.VITE_API_URL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeError: [errorHandler],
    beforeRequest: [authTokenInterceptor],
    afterResponse: [authTokenResponseHandler],
  },
});
